import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    id: String
  }

  toggleCustomization () {
    const dropdown = document.getElementById(this.idValue)
    const input = dropdown.querySelector("input[type='checkbox']")
    input.checked = !input.checked
    dropdown.classList.toggle('!border-black-100')
  }

  removeCustomization () {
    const dropdown = document.getElementById(this.idValue)
    const input = dropdown.querySelector("input[type='checkbox']")
    input.checked = false
    dropdown.removeAttribute('open')
    dropdown.classList.remove('!border-black-100')
  }

  focusInitialField () {
    setTimeout(function () { document.getElementById('initials').focus() }, 200)
  }

  removeInitials () {
    document.getElementById('initials').value = null
  }
}
